<template>
    <q-page>
        <div>
            <div class="row justify-center">
                <div
                    class="row bg-white"
                    style="width: 100%; max-width: 1200px;"
                >
                    <q-stepper
                        class="full-width"
                        v-model="stepper"
                        ref="stepper"
                        color="primary"
                        alternative-labels
                        :vertical="isMobile"
                        animated
                    >
                        <q-step
                            name="preencher-dados"
                            title="Preencher Dados"
                            icon="format_list_bulleted"
                        >
                            <q-preencher-dados
                                ref="preencherDados"
                                v-model:preencherDados="solicitacaoSenhaDados"
                                :desabilita-campos="readOnly"
                            />

                            <q-stepper-navigation>
                                <q-btn
                                    label="Continuar"
                                    icon-right="navigate_next"
                                    @click="$_nextPrencherDados"
                                    color="primary"
                                    :disabled="readOnly"
                                />
                            </q-stepper-navigation>
                        </q-step>

                        <q-step
                            name="contrato"
                            title="Contrato"
                            icon="import_contacts"
                        >
                            <q-contrato
                                @contrato="$_contrato"
                            />

                            <q-stepper-navigation class="q-gutter-x-md">
                                <q-btn
                                    label="Continuar"
                                    icon-right="navigate_next"
                                    @click="$_nextContrato"
                                    color="primary"
                                />
                            </q-stepper-navigation>
                        </q-step>

                        <q-step
                            name="termo-adesao"
                            title="Termo de Adesão"
                            icon="receipt"
                        >
                            <q-termo-adesao
                                ref="termoAdesao"
                                v-model:termoAdesao="solicitacaoSenhaDados"
                                :id-solicitacao-senha="idSolicitacaoSenha"
                            />

                            <q-stepper-navigation class="q-gutter-x-md">
                                <q-btn
                                    label="Voltar"
                                    icon="navigate_before"
                                    @click="$_previousStep"
                                    color="primary"
                                />
                                <q-btn
                                    label="Continuar"
                                    icon-right="navigate_next"
                                    @click="$_nextTermoAdesao"
                                    color="primary"
                                />
                            </q-stepper-navigation>
                        </q-step>

                        <q-step
                            name="nova-senha"
                            title="Nova senha"
                            icon="lock"
                            v-if="isMobile"
                        >
                            <q-captura-senha
                                ref="CapturaSenha"
                                v-model:capturaSenha="solicitacaoSenhaDados"
                                :id-solicitacao-senha="idSolicitacaoSenha"
                            />

                            <q-stepper-navigation class="q-gutter-x-md">
                                <q-btn
                                    v-if="isMobile"
                                    label="Voltar"
                                    icon="navigate_before"
                                    @click="$_previousStep"
                                    color="primary"
                                />
                                <q-btn
                                    label="Continuar"
                                    icon-right="navigate_next"
                                    @click="$_nextNovaSenha"
                                    color="primary"
                                />
                            </q-stepper-navigation>
                        </q-step>

                        <q-step
                            name="senha-salva"
                            title="Senha Salva"
                            icon="done_all"
                        >
                            <q-senha-salva />

                            <q-stepper-navigation class="q-gutter-x-md q-gutter-y-sm">
                                <q-btn
                                    label="Pagamento"
                                    icon="payments"
                                    @click="$_finalizarSenhaSalvar"
                                    color="primary"
                                />

                                <q-btn
                                    label="Ir as compras"
                                    icon="shopping_cart"
                                    @click="$_abrirSite"
                                    color="green"
                                />
                            </q-stepper-navigation>
                        </q-step>
                    </q-stepper>
                </div>
            </div>
        </div>
    </q-page>
</template>

<script>

import QPreencherDados from '@/components/SolicitacaoSenha/preencherDados'
import QContrato from '@/components/SolicitacaoSenha/contrato'
import QSenhaSalva from '@/components/SolicitacaoSenha/senhaSalva'
import QTermoAdesao from '@/components/SolicitacaoSenha/termoAdesao'
import QCapturaSenha from '@/components/SolicitacaoSenha/capturaSenha'

import CartaoSolicitacaoSenhaService from '@/services/cartao_solicitacao_senha'

import MixinCartaoSolicitacaoSenha from '@/mixins/mixinCartaoSolicitacaoSenha'
import MixinMessages from '@/mixins/mixinMessages'

import store from '@/store/index'

export default {
    name: 'QSolicitacaoSenha',

    components: {
        QPreencherDados,
        QContrato,
        QSenhaSalva,
        QTermoAdesao,
        QCapturaSenha
    },

    mixins: [
        MixinCartaoSolicitacaoSenha,
        MixinMessages
    ],

    data() {
        return {
            stepper: 'preencher-dados',

            solicitacaoSenhaDados: {
                pessoais: {
                    nome: '',
                    cpf: '',
                    nascimento: '',
                    email: '',
                    telefone: '',
                },
                endereco: {
                    cep: '',
                    cidade: '',
                    logradouro: '',
                    tipo_logradouro: '',
                    estado: '',
                    numero: '',
                    bairro: ''
                },
                cliente: '',
                codAcesso: '',
                senha: '',
                confSenha: '',
                aceiteTermo: false
            },

            idSolicitacaoSenha: null,

            readOnly: false,

            contrato: false
        }
    },

    computed: {
        isMobile() {
            return this.$q.platform.is.mobile
        }
    },
    mounted() {
        this.$_getSolicitacaoSenha()
    },

    methods: {
        $_nextPrencherDados() {
            if (this.$refs.preencherDados.validaCampos) {
                this.$_postSolicitacaoSenha()
            }
        },

        $_nextNovaSenha() {
            if (this.$refs.CapturaSenha.validaCampos) {
                this.$_putSolicitacaoSenha(this.solicitacaoSenhaDados.codAcesso, this.solicitacaoSenhaDados.senha)
            }
        },

        $_getSolicitacaoSenha() {
            this.showLoading({ message: 'Buscando solicitação de senha, Aguarde!' })
            CartaoSolicitacaoSenhaService.get({
                id_cliente: store.getters.getUsuarioLogado.id,
                sort: '-data_solicitacao'
            })
                .then(({ data }) => {
                    this.hideLoading()
                    if (data[0]) {
                        switch (data[0].status) {
                        case this.STATUS_APROVADO:
                            this.stepper = 'contrato'
                            this.idSolicitacaoSenha = data[0].id
                            break
                        case this.STATUS_AGUARDANDO_ANALISE:
                            this.$_senhaSolicitacaoAnalise(
                                'Cadastro de Senha',
                                'Você já possui uma solicitação de senha em andamento! Por favor, aguarde o contato da nossa central de crédito.'
                            )
                            break
                        }
                    }
                })
                .catch(e => {
                    this.hideLoading()
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro ao buscar a solicitação de senha.',
                        error: e
                    })
                })
        },

        $_postSolicitacaoSenha() {
            this.showLoading({ message: 'Criando solicitação de senha, Aguarde!' })
            const pessoal = this.solicitacaoSenhaDados.pessoais
            const endereco = this.solicitacaoSenhaDados.endereco
            CartaoSolicitacaoSenhaService.post({
                id_cartao: store.getters.getUsuarioLogado.id_cartao,
                nome_completo: pessoal.nome,
                cpf: pessoal.cpf.replace(/[^\d]+/g, ''),
                data_nascimento: pessoal.nascimento,
                celular: pessoal.telefone.replace(/[().\s-]+/g, ''),
                tipo_logradouro: endereco.tipo_logradouro.value ? endereco.tipo_logradouro.value : endereco.tipo_logradouro,
                logradouro: endereco.logradouro,
                numero: endereco.numero,
                cidade: endereco.cidade,
                uf: endereco.estado.value ? endereco.estado.value : endereco.estado,
                cep: endereco.cep.replace(/[^\d]+/g, ''),
                bairro: endereco.bairro,
                email: pessoal.email
            }).then(({ data }) => {
                this.hideLoading()
                if (data.status === this.STATUS_APROVADO) {
                    this.idSolicitacaoSenha = data.id
                    this.$_senhaAprovada()
                } else if (data.status === this.STATUS_AGUARDANDO_ANALISE) {
                    this.$_senhaSolicitacaoAnalise(
                        'Cadastro de Senha',
                        `Seu pedido de senha no Cartão ${process.env.VUE_APP_DESCRICAO_EMPRESA} foi enviado à nossa Central de Crédito. Caso seja necessário entraremos em contato nas próximas 24 horas, por favor, aguarde.`
                    )
                }
            })
                .catch(e => {
                    this.hideLoading()
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro ao solicitar a senha.',
                        error: e
                    })
                })
        },

        $_senhaSolicitacaoAnalise(title, message) {
            this.readOnly = true
            this.$q.dialog({
                title: title,
                message: message,
                ok: {
                    label: 'Fechar',
                    color: 'primary'
                },
                cancel: false,
                persistent: true
            })
                .onOk(() => {
                    this.$router.push('/home')
                })
        },

        $_senhaAprovada() {
            this.$q.dialog({
                title: 'Cadastro de Senha',
                message: `Sua solicitação de cadastro de senha para seu Cartão ${process.env.VUE_APP_DESCRICAO_EMPRESA} foi concluida. Você receberá um SMS com o código de acesso para criar a sua senha. Caso você já tenha recebido o código de acesso, clique no botão "<strong style="text-transform: uppercase;">Já tenho o código de acesso</strong>".`,
                ok: {
                    label: 'Já tenho o código de acesso',
                    color: 'primary'
                },
                cancel: {
                    label: 'Fechar',
                    color: 'secondary'
                },
                persistent: true,
                html: true
            })
                .onOk(() => {
                    this.$refs.stepper.next()
                })
                .onCancel(() => {
                    this.$router.push('/home')
                })
        },

        $_contrato(value) {
            this.contrato = value
        },

        $_nextTermoAdesao() {
            if (this.$refs.termoAdesao.validaCampos) {
                if (!this.isMobile){
                    this.$_putSolicitacaoSenha(this.solicitacaoSenhaDados.codAcesso, this.solicitacaoSenhaDados.senha)
                } else {
                    this.$refs.stepper.next()
                }
            }
        },

        $_previousStep() {
            this.$refs.stepper.previous()
        },

        $_putSolicitacaoSenha(codAcesso, senha) {
            this.showLoading({ message: 'Validando sua senha, Aguarde!' })
            CartaoSolicitacaoSenhaService.putCriarSenha(this.idSolicitacaoSenha, {
                codigo_acesso: codAcesso,
                senha: senha
            })
                .then(() => {
                    this.hideLoading()
                    this.$refs.stepper.next()
                })
                .catch(e => {
                    this.hideLoading()
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro ao criar a senha.',
                        error: e
                    })
                })
        },

        $_nextContrato() {
            if (!this.contrato) {
                this.warningMessage({
                    title: 'Atenção!',
                    message: 'Para prosseguir você deve aceitar os termos disponíveis no contrato.'
                })
            } else {
                this.$refs.stepper.next()
            }
        },

        $_finalizarSenhaSalvar() {
            this.$router.push('/home')
        },

        $_abrirSite() {
            window.open(process.env.VUE_APP_URL_ECOMMERCE)
        }
    }
}
</script>

<style lang="scss">
.q-stepper__step-inner{
    padding: 0px 24px 32px 45px !important;
}
</style>>
