<template>
    <div class="row">
        <div
            :class="`row bg-${$q.dark.isActive ? 'dark' : 'white'}`"
            style="width: 100%; max-width: 1200px;"
        >
            <div class="col full-height">
                <div class="row">
                    <q-contrato-aceite
                        :estilo="`height: ${ !$q.screen.lt.md ? `${$q.screen.height - 330}px` : `${$q.screen.height - 350}px`}`"
                    />
                </div>
                <div class="row q-mt-md">
                    <q-toggle
                        v-model="contratoAceito"
                        label="Li e aceito os termos disponíveis no contrato."
                        :disable="loading"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import QContratoAceite from '@/components/shared/QContratoAceite'

export default {
    name: 'QContratoSolicitacao',

    components: {
        QContratoAceite
    },

    data() {
        return {
            contratoAceito: false
        }
    },

    watch: {
        contratoAceito: function(value) {
            this.$emit('contrato', value)
        }
    },

    mounted() {
        this.$emit('contrato', this.contratoAceito)
    }
}
</script>

<style>

</style>
