<template>
    <div>
        <div class="col">
            <div class="col text-bold q-px-xs q-pt-md">
                DADOS PESSOAIS
            </div>
            <div class="col q-pa-xs">
                <q-input
                    ref="nomeCompleto"
                    v-model="dados.pessoais.nome"
                    label="Nome Completo *"
                    :readonly="desabilitaCampos"
                    square
                    filled
                    :rules="[(val) => !!val || 'Informe seu nome completo']"
                    lazy-rules
                />
            </div>
            <div class="row q-mt-sm q-gutter">
                <div class="col-12 col-md-6 q-pa-xs">
                    <q-input
                        v-model="dados.pessoais.cpf"
                        label="CPF"
                        mask="###.###.###-##"
                        unmasked-value
                        square
                        filled
                        readonly
                    />
                </div>
                <div class="col-12 col-md-6 q-pa-xs">
                    <q-input
                        v-model="dados.pessoais.nascimento"
                        label="Data de Nascimento *"
                        mask="##/##/####"
                        square
                        filled
                        readonly
                    />
                </div>
            </div>
            <div class="row q-mt-sm">
                <div class="col-12 col-md-6 q-pa-xs">
                    <q-input
                        ref="email"
                        v-model="dados.pessoais.email"
                        label="E-mail *"
                        type="email"
                        :readonly="desabilitaCampos"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe seu e-mail'
                        ]"
                        lazy-rules
                    />
                </div>
                <div class="col-12 col-md-6 q-pa-xs">
                    <q-input
                        ref="telefone"
                        v-model="dados.pessoais.telefone"
                        label="Telefone*"
                        type="tel"
                        mask="(##) #########"
                        unmasked-value
                        :readonly="desabilitaCampos"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe seu número de telefone',
                            (val) => val.length >= 10 || 'Informe um telefone válido',
                        ]"
                        lazy-rules
                    />
                </div>
            </div>
        </div>
        <div class="col q-mt-lg">
            <div class="col text-bold q-px-xs">
                ENDEREÇO
            </div>
            <div class="row">
                <div class="col-12 col-md-3 q-pa-xs">
                    <q-input
                        ref="cep"
                        v-model="dados.endereco.cep"
                        label="CEP *"
                        mask="##.###-###"
                        unmasked-value
                        :readonly="desabilitaCampos"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe seu CEP',
                            (val) => val.length >= 8 || 'Este campo deve ter 8 caracteres',
                        ]"
                        lazy-rules
                    />
                </div>
                <div class="col-12 col-md-3 q-pa-xs">
                    <q-input
                        ref="cidade"
                        v-model="dados.endereco.cidade"
                        label="Cidade *"
                        :readonly="desabilitaCampos"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe sua cidade',
                        ]"
                        lazy-rules
                    />
                </div>
                <div class="col-12 col-md-6 q-pa-xs">
                    <q-input
                        ref="logradouro"
                        v-model="dados.endereco.logradouro"
                        label="Logradouro *"
                        :readonly="desabilitaCampos"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe seu logradouro',
                        ]"
                        lazy-rules
                    />
                </div>
            </div>
            <div class="row q-mt-sm">
                <div class="col-12 col-md-3 q-pa-xs">
                    <q-select
                        ref="tipoLogradouro"
                        v-model="dados.endereco.tipo_logradouro"
                        :options="tipoLogradouroOptions"
                        label="Tipo de Logradouro *"
                        :readonly="desabilitaCampos"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe seu tipo de logradouro'
                        ]"
                        lazy-rules
                    />
                </div>
                <div class="col-12 col-md-3 q-pa-xs">
                    <q-select
                        ref="estado"
                        v-model="dados.endereco.estado"
                        :options="estadoOptions"
                        label="Estado *"
                        :readonly="desabilitaCampos"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe seu estado'
                        ]"
                        lazy-rules
                    />
                </div>
                <div class="col-12 col-md-3 q-pa-xs">
                    <q-input
                        ref="numeroCasa"
                        v-model="dados.endereco.numero"
                        label="Número *"
                        :readonly="desabilitaCampos"
                        @keyup="() => { dados.endereco.numero = dados.endereco.numero.replace(/[^0-9]+/g, '') }"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe o número da sua casa'
                        ]"
                        lazy-rules
                    />
                </div>
                <div class="col-12 col-md-3 q-pa-xs">
                    <q-input
                        ref="bairro"
                        v-model="dados.endereco.bairro"
                        label="Bairro *"
                        :readonly="desabilitaCampos"
                        square
                        filled
                        :rules="[
                            (val) => !!val || 'Informe seu bairro'
                        ]"
                        lazy-rules
                    />
                </div>
            </div>
        </div>
        <div class="row q-mt-lg text-subtitle2">
            * Itens Obrigatórios
        </div>
    </div>
</template>

<script>
import { date } from 'quasar'

import CEPService from '@/services/cep'

import MixinCEP from '@/mixins/mixinCEP'
import MixinMessages from '@/mixins/mixinMessages'

import store from '@/store/index'

export default {
    name: 'QPreencherDados',

    mixins: [
        MixinCEP,
        MixinMessages
    ],

    props: {
        modelValue: {
            type: Object,
            requird: true
        },

        desabilitaCampos: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            dados: {
                pessoais: {
                    nome: '',
                    cpf: '',
                    nascimento: '',
                    email: '',
                    telefone: '',
                },
                endereco: {
                    cep: '',
                    cidade: '',
                    logradouro: '',
                    tipo_logradouro: '',
                    estado: '',
                    numero: '',
                    bairro: ''
                },
                cliente: '',
                codAcesso: '',
                senha: '',
                confSenha: '',
                aceiteTermo: false
            },

            tipoLogradouroOptions: [],

            estadoOptions: []
        }
    },

    computed: {
        validaCampos() {
            this.$refs.nomeCompleto.validate()
            this.$refs.email.validate()
            this.$refs.telefone.validate()
            this.$refs.cep.validate()
            this.$refs.cidade.validate()
            this.$refs.logradouro.validate()
            this.$refs.tipoLogradouro.validate()
            this.$refs.estado.validate()
            this.$refs.numeroCasa.validate()
            this.$refs.bairro.validate()

            const errors = [
                !this.$refs.nomeCompleto.hasError,
                !this.$refs.email.hasError,
                !this.$refs.telefone.hasError,
                !this.$refs.cep.hasError,
                !this.$refs.cidade.hasError,
                !this.$refs.logradouro.hasError,
                !this.$refs.tipoLogradouro.hasError,
                !this.$refs.estado.hasError,
                !this.$refs.numeroCasa.hasError,
                !this.$refs.bairro.hasError
            ]

            if (errors.includes(false)) {
                return false
            }

            return true
        }
    },

    watch: {
        dados: {
            deep: true,
            handler(value) {
                this.$emit('update:preencherDados', value)
            }            
        },

        modelValue: function(value) {
            this.dados = value
        },

        'dados.endereco.cep': function(value) {
            this.$_buscaCEP(value)
        }
    },

    mounted() {
        this.dados.pessoais.cpf = ('00000000000' + store.getters.getUsuarioLogado.cpf).slice(-11)
        this.dados.pessoais.nascimento = date.formatDate(store.getters.getUsuarioLogado.nascimento + 'T00:00:00', 'DD/MM/YYYY')
        this.tipoLogradouroOptions = this.tipoLogradouro
        this.estadoOptions = this.UFs
        this.$_carregarEstados()
        this.$_carregarTipoLogradouros()
    },

    methods: {
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },

        $_buscaCEP(value) {
            const cep = value.replace(/[^\d]+/g, '')
            if (cep.length === 8) {
                this.showLoading({ message: 'Buscando dados do CEP, Aguarde!' })
                CEPService.getCep(cep)
                    .then(({ data }) => {
                        this.dados.endereco.logradouro = data.logradouro ? data.logradouro : ''
                        this.dados.endereco.tipo_logradouro = data.logradouro ? data.logradouro.substr(0, data.logradouro.indexOf(' ')) : ''
                        this.dados.endereco.logradouro = data.logradouro ? this.dados.endereco.logradouro.toLowerCase().replace(data.logradouro.substr(0, data.logradouro.indexOf(' ')).toLowerCase(), '').trim(): ''
                        this.dados.endereco.logradouro = this.$_toCamelCase(this.dados.endereco.logradouro)
                        this.dados.endereco.estado = data.estado ? data.estado : ''
                        this.dados.endereco.bairro = data.bairro ? data.bairro : ''
                        this.dados.endereco.cidade = data.cidade ? data.cidade : ''
                        this.hideLoading()
                    })
                    .catch(e => {
                        this.hideLoading()
                        console.log(e)
                        if (e.response.status === 404) {
                            this.warningMessage({
                                title: 'Ops, algo de errado aconteceu!',
                                message: 'CEP não encontrado'
                            })
                        } else {
                            this.warningMessage({
                                title: 'Ops, algo de errado aconteceu!',
                                message: 'Não foi possível autocompletar os campos. Preencha o endereço manualmente.'
                            })
                        }
                    })
            }
        },

        $_carregarEstados() {
            this.showLoading({ message: 'Buscando estados, Aguarde!' })
            CEPService.getEstados()
                .then(({ data }) => {
                    this.hideLoading()
                    this.estadoOptions = []
                    this.estadoOptions = [
                        ...data.map(el => ({
                            value: el.sigla,
                            label: el.sigla
                        }))
                    ]
                })
                .catch(e => {
                    this.hideLoading()
                    console.log(e)
                })
        },

        $_carregarTipoLogradouros() {
            this.showLoading({ message: 'Buscando tipos de logradouros, Aguarde!' })
            CEPService.getTipoLogradouro()
                .then(({ data }) => {
                    this.hideLoading()
                    this.tipoLogradouroOptions = []
                    this.tipoLogradouroOptions = [
                        ...data.map(el => ({
                            value: el.descricao.toLowerCase(),
                            label: el.descricao
                        }))
                    ]
                })
                .catch(e => {
                    this.hideLoading()
                    console.log(e)
                })
        },

        $_toCamelCase(str) {
            var lower = String(str).toLowerCase()
            return lower.replace(/(^| )(\w)/g, function(x) {
                return x.toUpperCase()
            })
        }
    }
}
</script>

<style>

</style>
