import ServicesAuth from './servicesAuth'

class EmpresaService extends ServicesAuth {
    constructor() {
        super({
            url: '/empresa',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }
}

export default new EmpresaService()
