export default {
    data() {
        return {
            tipoLogradouro: [
                { label: 'ATALHO', value: 'atalho' },
                { label: 'AVENIDA', value: 'avenida' },
                { label: 'BAIXA', value: 'baixa' },
                { label: 'BECO', value: 'beco' },
                { label: 'BLOCO', value: 'bloco' },
                { label: 'BLOCOS', value: 'blocos' },
                { label: 'COMUNIDADE', value: 'comunidade' },
                { label: 'CONDOMINIO', value: 'condominio' },
                { label: 'CONJUNTO', value: 'conjunto' },
                { label: 'CORREDOR', value: 'corredor' },
                { label: 'DISTRITO', value: 'distrito' },
                { label: 'EIXO INDUSTRIAL', value: 'eixo industrial' },
                { label: 'ESTACAO', value: 'estacao' },
                { label: 'ESTACIONAMENTO', value: 'estacionamento' },
                { label: 'ESTADIO', value: 'estadio' },
                { label: 'ESTRADA', value: 'estrada' },
                { label: 'FAVELA', value: 'favela' },
                { label: 'FAZENDA', value: 'fazenda' },
                { label: 'GALERIA', value: 'galeria' },
                { label: 'ILHA', value: 'ilha' },
                { label: 'INTERIOR', value: 'interior' },
                { label: 'LAGO', value: 'lago' },
                { label: 'LAGOA', value: 'lagoa' },
                { label: 'LARGO', value: 'largo' },
                { label: 'LOTEAMENTO', value: 'loteamento' },
                { label: 'MORRO', value: 'morro' },
                { label: 'PARQUE', value: 'parque' },
                { label: 'PRACA', value: 'praca' },
                { label: 'RODOVIA', value: 'rodovia' },
                { label: 'RUA', value: 'rua' },
                { label: 'TRAVESSA', value: 'travessa' },
                { label: 'VILA', value: 'vila' }
            ],

            UFs: [
                { label: 'AC', value: 'AC' },
                { label: 'AL', value: 'AL' },
                { label: 'AP', value: 'AP' },
                { label: 'AM', value: 'AM' },
                { label: 'BA', value: 'BA' },
                { label: 'CE', value: 'CE' },
                { label: 'DF', value: 'DF' },
                { label: 'ES', value: 'ES' },
                { label: 'GO', value: 'GO' },
                { label: 'MA', value: 'MA' },
                { label: 'MT', value: 'MT' },
                { label: 'MS', value: 'MS' },
                { label: 'MG', value: 'MG' },
                { label: 'PA', value: 'PA' },
                { label: 'PB', value: 'PB' },
                { label: 'PR', value: 'PR' },
                { label: 'PE', value: 'PE' },
                { label: 'PI', value: 'PI' },
                { label: 'RJ', value: 'RJ' },
                { label: 'RS', value: 'RS' },
                { label: 'RN', value: 'RN' },
                { label: 'RO', value: 'RO' },
                { label: 'RR', value: 'RR' },
                { label: 'SC', value: 'SC' },
                { label: 'SP', value: 'SP' },
                { label: 'SE', value: 'SE' },
                { label: 'TO', value: 'TO' }
            ]
        }
    }
}
