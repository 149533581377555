<template>
    <q-card
        :style="estilo"
        class="col scroll"
        bordered
        flat
    >
        <q-card-section v-if="loading">
            <q-banner-mensagem
                v-if="naoEncontrado"
                :mensagem="naoEncontrado"
            />
            <q-skeleton-loading />
        </q-card-section>
        <q-card-section
            class="bg-white text-black"
            v-else
            v-html="termo"
        />
    </q-card>
</template>

<script>

import ParametrosService from '@/services/parametrosProposta'
import PropostaService from '@/services/proposta'
import ClienteServices from '@/services/cliente'
import EmpresaService from '@/services/empresa'
import QSkeletonLoading from '@/components/shared/QSkeletonLoading'
import QBannerMensagem from '@/components/shared/QBannerMensagem'
import MixinMessages from '@/mixins/mixinMessages'
import store from '@/store/index'

import { date } from 'quasar'

export default {
    name: 'QTermoAdesao',

    components: {
        QSkeletonLoading,
        QBannerMensagem
    },

    mixins: [
        MixinMessages
    ],

    props: {
        termoCliente: {
            type: Boolean,
            default: true
        },
        estilo: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            termo: null,
            empresa: {},
            proposta: {},
            cliente: {},
            loading: true,
            id_proposta: null,
            naoEncontrado: null,
        }
    },

    mounted() {
        if (!this.termoCliente) {
            this.id_proposta = this.$route.params.id_proposta
        }
        this.$_getTermoAdesao()
    },

    methods: {
        $_getTermoAdesao() {
            ParametrosService.getParametro(`HTML_TERMO_ADESAO_${String(process.env.VUE_APP_DESCRICAO_EMPRESA).replace(/[Éé]/g,"E").toUpperCase()}`)
                .then(({ data }) => {
                    this.termo = data[0].valor
                    this.$_getEmpresa()
                })
                .catch(() => {
                    this.naoEncontrado = "Atenção! Não foi possivel localizar os dados do termo de adesão, entre em contato com a central."
                })
        },

         $_getEmpresa() {
            EmpresaService.get()
                .then(({ data }) => {
                    this.empresa = data
                    if (this.termoCliente) {
                        this.$_getCliente()
                    } else {
                        this.$_getProposta()
                    }
                })
                .catch(() => {
                    this.naoEncontrado = "Atenção! Não foi possivel localizar os dados da empresa, entre em contato com a central."
                })
        },

        $_getProposta() {
            PropostaService.getByID(this.id_proposta, { fields: 'nome,rg,cpf,endereco_logradouro,endereco_numero,endereco_complemento,endereco_cidade,endereco_uf,endereco_bairro,endereco_cep,created_at' })
                .then(({ data }) => {
                    this.proposta = data
                    this.$_atualizaValoresTermoProposta()
                })
                .catch(() => {
                    this.naoEncontrado = "Atenção! Não foi possivel localizar a proposta, entre em contato com a central."
                })
        },

        $_getCliente() {
            ClienteServices.getByID(store.getters.getUsuarioLogado.id, { fields: 'nome,codigo,identidade,cpf,endereco_logradouro,endereco_numero,endereco_complemento,endereco_cidade,endereco_uf,endereco_bairro,endereco_cep,data_cadastro'})
                .then(({ data }) => {
                    this.cliente = data
                    this.$_atualizaValoresTermoCliente()
                })
                .catch(() => {
                    this.naoEncontrado = "Atenção! Não foi possivel localizar o cliente, entre em contato com a central."
                })
        },

        $_atualizaValoresTermoProposta(){
            const values = {
                "{{CPF}}": String(('00000000000' + this.proposta.cpf).slice(-11)).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
                "{{NOME}}": this.proposta.nome,
                "{{CODIGO}}": this.proposta.codigo ? this.proposta.codigo : '' ,
                "{{IDENTIDADE}}": this.proposta.rg,
                "{{ENDERECO_LOGRADOURO}}": this.proposta.endereco_logradouro,
                "{{ENDERECO_NUMERO}}": this.proposta.endereco_numero,
                "{{ENDERECO_COMPLEMENTO}}": this.proposta.endereco_complemento || '',
                "{{ENDERECO_CIDADE}}": this.proposta.endereco_cidade,
                "{{ENDERECO_UF}}": this.proposta.endereco_uf,
                "{{ENDERECO_BAIRRO}}": this.proposta.endereco_bairro,
                "{{ENDERECO_CEP}}": this.proposta.endereco_cep || '',
                "{{DATA_CADASTRO}}": date.formatDate(this.proposta.created_at, 'DD/MM/YYYY'),
                "{{RAZAO_SOCIAL_EMPRESA}}": this.empresa.razao_social,
                "{{NOME_FANTASIA_EMPRESA}}": this.empresa.nome_fantasia,
                "{{CIDADE_EMPRESA}}": this.empresa.cidade,
                "{{UF_EMPRESA}}": this.empresa.uf,
                "{{ENDERECO_EMPRESA}}": this.empresa.endereco,
                "{{BAIRRO_EMPRESA}}": this.empresa.bairro,
                "{CEP_EMPRESA}": this.empresa.cep
            }
            this.termo = this.termo.replace(
                /{{CPF}}|{{NOME}}|{{CODIGO}}|{{IDENTIDADE}}|{{ENDERECO_LOGRADOURO}}|{{ENDERECO_NUMERO}}|{{ENDERECO_COMPLEMENTO}}|{{ENDERECO_CIDADE}}|{{ENDERECO_UF}}|{{ENDERECO_BAIRRO}}|{{ENDERECO_CEP}}|{{ENDERECO_BAIRRO}}|{{ENDERECO_CEP}}|{{DATA_CADASTRO}}|{{RAZAO_SOCIAL_EMPRESA}}|{{NOME_FANTASIA_EMPRESA}}|{{CIDADE_EMPRESA}}|{{UF_EMPRESA}}|{{ENDERECO_EMPRESA}}|{{BAIRRO_EMPRESA}}|{{CEP_EMPRESA}}/gi,
                (matched) => values[matched]
            )
            this.loading = false
        },

        $_atualizaValoresTermoCliente() {
            const values = {
                "{{CPF}}": String(('00000000000' + this.cliente.cpf).slice(-11)).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
                "{{NOME}}": this.cliente.nome,
                "{{CODIGO}}": this.cliente.codigo,
                "{{IDENTIDADE}}": this.cliente.identidade,
                "{{ENDERECO_LOGRADOURO}}": this.cliente.endereco_logradouro,
                "{{ENDERECO_NUMERO}}": this.cliente.endereco_numero,
                "{{ENDERECO_COMPLEMENTO}}": this.cliente.endereco_complemento,
                "{{ENDERECO_CIDADE}}": this.cliente.endereco_cidade,
                "{{ENDERECO_UF}}": this.cliente.endereco_uf,
                "{{ENDERECO_BAIRRO}}": this.cliente.endereco_bairro,
                "{{ENDERECO_CEP}}": this.cliente.endereco_cep,
                "{{DATA_CADASTRO}}": date.formatDate(this.cliente.data_cadastro + 'T00:00:00', 'DD/MM/YYYY'),
                "{{RAZAO_SOCIAL_EMPRESA}}": this.empresa.razao_social,
                "{{NOME_FANTASIA_EMPRESA}}": this.empresa.nome_fantasia,
                "{{CIDADE_EMPRESA}}": this.empresa.cidade,
                "{{UF_EMPRESA}}": this.empresa.uf,
                "{{ENDERECO_EMPRESA}}": this.empresa.endereco,
                "{{BAIRRO_EMPRESA}}": this.empresa.bairro,
                "{CEP_EMPRESA}": this.empresa.cep
            }
            this.termo = this.termo.replace(
                /{{CPF}}|{{NOME}}|{{CODIGO}}|{{IDENTIDADE}}|{{ENDERECO_LOGRADOURO}}|{{ENDERECO_NUMERO}}|{{ENDERECO_COMPLEMENTO}}|{{ENDERECO_CIDADE}}|{{ENDERECO_UF}}|{{ENDERECO_BAIRRO}}|{{ENDERECO_CEP}}|{{ENDERECO_BAIRRO}}|{{ENDERECO_CEP}}|{{DATA_CADASTRO}}|{{RAZAO_SOCIAL_EMPRESA}}|{{NOME_FANTASIA_EMPRESA}}|{{CIDADE_EMPRESA}}|{{UF_EMPRESA}}|{{ENDERECO_EMPRESA}}|{{BAIRRO_EMPRESA}}|{{CEP_EMPRESA}}/gi,
                (matched) => values[matched]
            )
            this.loading = false
        }
    }
}
</script>

<style>

</style>
