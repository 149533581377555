export default {
    data() {
        return {
            STATUS_APROVADO: 1,
            STATUS_AGUARDANDO_ANALISE: 2,
            STATUS_EM_ANALISE: 3,
            STATUS_REPROVADO: 4,
            STATUS_SENHA_CADASTRADA: 5,
            STATUS_CANCELADO: 6
        }
    }
}
