<template>
    <div class="row">
        <q-captura-senha
            ref="CapturaSenha"
            v-model:capturaSenha="dados"
            v-if="!isMobile"
            :id-solicitacao-senha="idSolicitacaoSenha"
        />
        <div
            :class="`col ${!isMobile ? 'q-pa-sm': ''}`"
        >
            <q-card
                class="col scroll"
                :style="`height: ${ !isMobile ? `${$q.screen.height - 330}px`: `${$q.screen.height - 330}px`};`"
                flat
                bordered
            >
                <q-termo-adesao
                    :termoCliente="true"
                    :estilo="`padding: ${ !isMobile ? '20px' : '0px'}`"
                />
            </q-card>
            <q-toggle
                v-model="dados.aceiteTermo"
                label="Li e aceito os termos disponíveis no termo de adesão"
            />
            <p v-if="error" class="text-negative error-aceite-termo">Para prosseguir, você deve aceitar os termos disponíveis no termo de adesão.'</p>
        </div>
    </div>
</template>
<script>

import QCapturaSenha from './capturaSenha'
import QTermoAdesao from '@/components/shared/QTermoAdesao'
import MixinMessages from '@/mixins/mixinMessages'

export default {
    name: 'QNovaSenha',

    components: {
        QCapturaSenha,
        QTermoAdesao
    },

    mixins: [
        MixinMessages
    ],

    props: {
        value: {
            type: Object,
            requird: true
        },
        
        idSolicitacaoSenha: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dados: {
                pessoais: {
                    nome: '',
                    cpf: '',
                    nascimento: '',
                    email: '',
                    telefone: '',
                },
                endereco: {
                    cep: '',
                    cidade: '',
                    logradouro: '',
                    tipo_logradouro: '',
                    estado: '',
                    numero: '',
                    bairro: ''
                },
                cliente: '',
                codAcesso: '',
                senha: '',
                confSenha: '',
                aceiteTermo: false
            },
            error: false
        }
    },

    computed: {
        isMobile() {
            return this.$q.platform.is.mobile
        },

        validaCampos() {
            //valida se tela menor que md ou maior q md e a captura de senha tiver validado
            if ((this.isMobile) || (!this.isMobile && this.$refs.CapturaSenha.validaCampos)) {
                return this.$_validaTermo()
            }
            return false
        }
    },

    watch: {
        dados: {
            deep: true,
            handler(value) {
                if (this.dados.aceiteTermo) {
                    this.error = false
                }
                this.$emit('update:termoAdesao', value)
            }            
        },

        value: function(value) {
            this.dados = value
        },
    },

    methods: {
        $_validaTermo() {
            if (!this.dados.aceiteTermo) {
                this.error = true
                return false
            }
            return true
        }
    }
}
</script>

<style lang="scss">
.error-aceite-termo {
    font-size: 12px;
    margin-left: 50px;
    padding: 0;
}
</style>
