import Services from './services'

class ParametrosServices extends Services {
    constructor() {
        super({
            url: '/parametro',
            config: {
                // Configurações/Params genéricas para todos os métodos desta classe
                baseURL: process.env.VUE_APP_API_PROPOSTA_CREDITO,
                headers: {
                    'api-version': process.env.VUE_APP_API_PROPOSTA_CREDITO_VERSION,
                    Authorization: process.env.VUE_APP_API_PROPOSTA_CREDITO_TOKEN
                },
                timeout: 60 * 1000
            }
        })
    }

    getParametro(parametro) {
        return this.axios.get(`${this.url}?nome=${parametro}`)
    }
}

export default new ParametrosServices()
