import Services from './services'

class CEPService extends Services {
    constructor() {
        super({
            config: {
                baseURL: process.env.VUE_APP_API_CEP,
                headers: {
                    Authorization: `JWT ${process.env.VUE_APP_API_CEP_TOKEN}`
                }
            }
        })
    }

    getCep(cep) {
        const req = `/cep/${cep}/`
        return this.axios.get(req)
    }

    getTipoLogradouro() {
        return this.axios.get('/tipo-logradouro?per_page=150')
    }

    getEstados() {
        return this.axios.get('/unidade-federacao?per_page=100')
    }
}

export default new CEPService()
