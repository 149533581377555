<template>
    <div class="col">
        <div class="row text-bold text-h6 q-mt-md">
            Senha cadastrada com sucesso
        </div>
        <div class="row">
            Agora você pode realizar compras no seu Cartão {{ empresa }}. Boas compras!
        </div>
    </div>
</template>

<script>
export default {
    name: 'QSenhaSalva',

    computed: {
        empresa() {
            return process.env.VUE_APP_DESCRICAO_EMPRESA
        }
    }
}
</script>

<style>

</style>
