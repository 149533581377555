import ServicesAuth from './servicesAuth'

class CartaoSolicitacaoSenhaService extends ServicesAuth {
    constructor() {
        super({
            url: '/cartoes-solicitacoes-senha',
            config: {
                baseURL: process.env.VUE_APP_API_CREDITO_URL,
                headers: {
                    authorization: `JWT ${process.env.VUE_APP_API_CREDITO_TOKEN}`
                },
                timeout: 60 * 1000
            }
        })
    }

    putCriarSenha(id, data) {
        return this.axios.put(`${this.url}/${id}/criar-senha`, data)
    }

    postReenvioCodigoAcesso(id) {
        return this.axios.post(`${this.url}/${id}/reenviar-codigo-acesso`)
    }
}

export default new CartaoSolicitacaoSenhaService()
