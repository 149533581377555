<template>
    <div class="col-12 col-md-4">
        <div class="col q-pa-xs">
            <q-input
                v-model="dados.cliente"
                label="Nome do cliente"
                readonly
                square
                filled
            />
        </div>
        <div class="col-12 q-pa-sm">
            <q-btn
                class="full-width"
                color="grey-7"
                :label="`${submitting ? mensagemAguardeTempo : 'Reenviar código de acesso'}`"
                @click="$_reenviarCodigoAcesso"
                :disabled="submitting"
            />
        </div>

        <div class="col q-pa-xs">
            <q-input
                ref="codigoAcesso"
                v-model="dados.codAcesso"
                label="Código de acesso *"
                hint="Digite o código que recebeu por SMS"
                :rules="[
                    val => !!val || 'É necessário preencher este campo'
                ]"
                lazy-rules
                square
                filled
            />
        </div>
        <div class="col q-pa-xs">
            <q-input
                ref="senha"
                v-model="dados.senha"
                maxlength="4"
                label="Senha *"
                hint="A senha deve conter somente 4 números"
                :type="isPwdSenha ? 'password' : 'text'"
                inputmode="numeric"
                :rules="[
                    val => !!val || 'É necessário preencher este campo',
                    val => val.length <= 4 || 'A senha deve possuir 4 dígitos numéricos',
                    val => this.validaSenha(val) || 'A senha não pode conter sequenciais ou numeros repetidos'
                ]"
                lazy-rules
                square
                filled
                @keyup="() => { dados.senha = dados.senha.replace(/[^0-9]+/g, '') }"
            >
                <template v-slot:append>
                    <q-icon
                        :name="isPwdSenha ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwdSenha = !isPwdSenha"
                    />
                </template>
            </q-input>
        </div>
        <div class="col q-pa-xs">
            <q-input
                ref="confirmacaoSenha"
                v-model="dados.confSenha"
                maxlength="4"
                label="Confirme a senha *"
                hint="Repita a senha digitada anteriormente"
                :type="isPwdConfSenha ? 'password' : 'text'"
                inputmode="numeric"
                :rules="[
                    val => !!val || 'É necessário preencher este campo',
                    val => val === dados.senha || 'As senhas não coincidem'
                ]"
                lazy-rules
                square
                filled
                @keyup="() => { dados.confSenha = dados.confSenha.replace(/[^0-9]+/g, '') }"
            >
                <template v-slot:append>
                    <q-icon
                        :name="isPwdConfSenha ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwdConfSenha = !isPwdConfSenha"
                    />
                </template>
            </q-input>
        </div>
    </div>
</template>

<script>
import ClienteServices from '@/services/cliente'
import CartaoSolicitacaoSenhaService from '@/services/cartao_solicitacao_senha'

import MixinMessages from '@/mixins/mixinMessages'

import store from '@/store/index'

export default {
    name: 'QCapturaSenha',

    mixins: [
        MixinMessages
    ],

    props: {
        value: {
            type: Object,
            requird: true
        },

        idSolicitacaoSenha: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            dados: {
                pessoais: {
                    nome: '',
                    cpf: '',
                    nascimento: '',
                    email: '',
                    telefone: '',
                },
                endereco: {
                    cep: '',
                    cidade: '',
                    logradouro: '',
                    tipo_logradouro: '',
                    estado: '',
                    numero: '',
                    bairro: ''
                },
                cliente: '',
                codAcesso: '',
                senha: '',
                confSenha: '',
                aceiteTermo: false
            },
            isPwdConfSenha: true,
            isPwdSenha: true,
            submitting: false,
            mensagemAguardeTempo: 'Enviando SMS...'
        }
    },

    computed: {
        validaCampos() {
            this.$refs.codigoAcesso.validate()
            this.$refs.senha.validate()
            this.$refs.confirmacaoSenha.validate()

            const errors = [
                !this.$refs.codigoAcesso.hasError,
                !this.$refs.senha.hasError,
                !this.$refs.confirmacaoSenha.hasError,
            ]

            if (errors.includes(false) || !this.senhaValida) {
                return false
            }
            return true
        },

        senhaValida() {
            return this.dados.senha === this.dados.confSenha
        }
    },

    watch: {
        dados: {
            deep: true,
            handler(value) {
                this.$emit('update:capturaSenha', value)
            }            
        },

        value: function(value) {
            this.dados = value
        },
    },

    mounted() {
        this.buscaCliente()
    },

    methods: {
        $_reenviarCodigoAcesso(){
            this.submitting = true
            CartaoSolicitacaoSenhaService.postReenvioCodigoAcesso(this.idSolicitacaoSenha)
                .then(() => {
                    this.successMessage({
                        title: 'SMS enviado',
                        message: 'Você receberá em breve o código de acesso por SMS!'
                    })
                    this.$_iniciarContadorSms()
                })
                .catch(e => this.errorMessage({
                    title: 'Ops, algo de errado aconteceu!',
                    message: 'Não conseguimos reenviar o código de acesso para você, tente novamente mais tarde!',
                    error: e
                })
                )
        },

        buscaCliente(){
            ClienteServices.getCliente(store.getters.getUsuarioLogado.id, 'nome')
                .then(({ data }) => {
                    this.dados.cliente = data.nome
                })
                .catch(e => this.errorMessage({
                    title: 'Ops, algo de errado aconteceu!',
                    message: 'Tivemos um problema para obter seus dados, tente novamente mais tarde',
                    error: e
                })
                )
        },

        $_iniciarContadorSms() {
            let minutos = process.env.VUE_APP_TEMPO_SMS
            let segundos = 59
            let tempoEsperaSms = ''
            minutos--
            const analiseTimer = setInterval(() => {
                segundos--
                if (segundos < 0) {
                    minutos--
                    segundos = 59
                    if (minutos < 0){
                        clearInterval(analiseTimer)
                        this.submitting = false
                    }
                }
                tempoEsperaSms = `${minutos}:${segundos < 10 ? `0${segundos}` : segundos}`
                this.mensagemAguardeTempo = `Você poderá reenviar o sms em: ${tempoEsperaSms}`
            }, 1000)
        },

        validaSenha(senha) {
            //Testa com regex numeros sequenciais ex:. 1111,2222
            const sequencial = function(){
                const regex =  /^(\d)\1+$/
                return regex.test(String(senha))
            }

            //Testa com regex numeros Ordem crescente ex:. 1234,6789
            const ordemCrescente = function(){
                const digitos = String(senha).split('')
                return parseInt(digitos[1]) === (parseInt((digitos[0]))+1) && 
                    parseInt(digitos[2]) === (parseInt((digitos[0]))+2) &&
                   parseInt(digitos[3]) === (parseInt((digitos[0]))+3)
            }

            //Testa com regex numeros Ordem decrescente ex:. 9876,4321
            const ordemDecrescente = function(){
                const digitos = String(senha).split('')
                return parseInt(digitos[2]) === (parseInt((digitos[3]))+1) && 
                    parseInt(digitos[1]) === (parseInt((digitos[3]))+2) &&
                   parseInt(digitos[0]) === (parseInt((digitos[3]))+3)
            }
            if(sequencial() || ordemCrescente() || ordemDecrescente()){
                //Rules do Quasar notifica o erro quando retorna false. Por isso foi apos testar é feito a inversão do valor
                return false
            }
            return true
        },
    }
}
</script>

<style>

</style>
